'use strict';

// ------- TEST ----------
module.exports = Object.freeze({
        prod: {
            ROOT: "https://api.angelvaluation.com/x/prod",
        },
        dev: {
            ROOT: "https://api.angelvaluation.com/x/dev",
        },
    }
);

