import React from "react";
function Index({ width }) {
    return (
        <>
			<div className="w-full mx-auto mb-6 md:mb-0">
                <p className="text-center pb-1 leading-9 text-lg">File upload in progress</p>
            	<p className="text-sm text-center text-yellow-400 font-bold pb-1">{width}% Complete</p>
                <div className="h-2 bg-gray-600  rounded-sm ">
                    <div className="bg-yellow-400 h-2 rounded-sm  relative" style={{width: `${width}%`}} />
                </div>
            </div>
        </>
    );
}
export default Index;