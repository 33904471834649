import React from "react";
import Layout from "../components/Layout";
import Progressbar from "../components/Progressbar";
import axios from "axios";
import mime from "mime";
const settings = require("../../settings");

const typeMap = {};

export default function uploads() {
  let oldPass = React.useRef("");
  let urlInput = React.useRef(null);
  let [error, setError] = React.useState("");
  let errorDiv = React.useRef(null);
  let [submitting, changeSubmitting] = React.useState(false);
  let [uploaded, changeUploaded] = React.useState(false);
  let [pass, changePass] = React.useState();
  let [address, changeAddress] = React.useState();
  let [uploadURL, changeUploadURL] = React.useState("hello gg wp yolo");
  let [file, changeFile] = React.useState();
  let [note, changeNote] = React.useState();
  const [progress, setProgress] = React.useState(0);

  let uploadFile = async (e) => {
    e.preventDefault();
    oldPass.current = pass;

    if (!file) {
      setError("Please pick a file");
    } else {
      changeSubmitting(true);
      const ext = file.name.split(".").pop();
      console.log(`uploading File: ${file.name} Ext:${ext}`);
      try {
        const body = await axios({
          method: "post",
          data: {
            ext: file.name.split(".").pop(),
            pass: pass,
            note: note,
            address: address,
            content_type: file.type,
          },
          url: `${process.env.API}/get-upload-url`,

        });
		const urlData = body.data.read.split('/');

        changeUploadURL(`${window.location.origin}/view/?file=${urlData[urlData.length-1]}`);
        const response = await axios({
          method: "put",
          url: body.data.write, // write URL
          data: file,
          headers: {
            "Content-Type": file.type,
          },
		  onUploadProgress: (progressEvent) => {
			  const totalLength = progressEvent.lengthComputable ? progressEvent.total : progressEvent.target.getResponseHeader('content-length') || progressEvent.target.getResponseHeader('x-decompressed-content-length');
			  if (totalLength !== null) {
			    setProgress(Math.round( (progressEvent.loaded * 100) / totalLength ))
			  }
			}	
        });
				setProgress(0)
        changeSubmitting(false);
        changeUploaded(true);

      } catch (e) {
        if (e.response) setError(e.response.data);
        else setError("An Error Occured");
        // setError(true);
        changeSubmitting(false);
        errorDiv.current.scrollIntoView();
      }
    }
  };
  let copyURL = (e) => {
    e.preventDefault();
    urlInput.current.select();
    urlInput.current.setSelectionRange(0, 99999);
    document.execCommand("copy");
  };
  return (
    <Layout>
      <div className="flex justify-center">
        <div className="lg:w-1/2 sm:w-full sm:m-5 border-1 ">
          <form>
            <div>
              <input
                className="w-full p-1 border-2  border-yellow-400 focus:border-2 focus:border-gray-400 rounded focus:rounded outline-none"
                defaultValue={oldPass.current}
                placeholder="password"
                type="text"
                name="pass"
                onChange={(e) => {
                  changePass(e.target.value);
                  setError(false);
                }}
              ></input>
            </div>
            <div className="my-3">
              <input
                className="w-full p-1 border-2 border-yellow-400 focus:border-2 focus:border-gray-400 rounded focus:rounded outline-none"
                type="text"
                placeholder="address"
                name="address"
                onChange={(e) => {
                  changeAddress(e.target.value);
                  setError(false);
                }}
              ></input>
            </div>
            <div className="mb-3">
              <input
                className="w-full p-1 border-2 border-yellow-400 focus:border-2 focus:border-gray-400 rounded focus:rounded outline-none"
                type="text"
                placeholder="note"
                name="note"
                onChange={(e) => {
                  changeNote(e.target.value);
                  setError(false);
                }}
              ></input>
            </div>

            {!uploaded ? (
              <>
                <div className="text-center relative align-middle border-4 border-gray-300 border-dashed bg-gray-100">
                  <input
                    type="file"
                    className="w-full h-96  opacity-0 border-2 border-black z-20"
                    onChange={(e) => {
                      setError(false);
                      changeFile(e.target.files[0]);
                    }}
                  ></input>
                  {!file ? (
                    <div className="absolute top-1/2 right-1/3 left-1/3">
                      <span className="z-0  text-gray-600">
                        Click or drag a file
                      </span>
                    </div>
                  ) : (
                    <div className="absolute top-1/2 right-1/3 left-1/3">
                      <span className="z-0  text-gray-600">{file.name}</span>
                    </div>
                  )}
                </div>
                <button
                  onClick={uploadFile}
                  disabled={submitting}
                  className={
                    "w-full p-3 border-2 border-gray-500 bg-gray-400 text-white my-3 rounded-md hover:bg-gray-900 " +
                    (submitting ? "bg-gray-900" : "")
                  }
                >
                  {submitting ? (
                    <div
                      className="animate-spin h-5 w-5 mx-auto border-t-4  border-4 rounded-full border-yellow-400"
                      style={{ borderTopColor: "gray" }}
                    ></div>
                  ) : (
                    "Upload"
                  )}

                </button>
				{submitting && <Progressbar width={progress} />}
                {error && (
                  <div
                    ref={errorDiv}
                    className="w-full bg-red-200 text-red-500 p-3 rounded-md border-2 border-red-300 my-3"
                  >
                    {error}
                  </div>
                )}
              </>
            ) : (
              <>
                <div className="w-full bg-green-200 p-3 rounded-md mb-5">
                  <div className="text-green-700">
                    Upload Successful. Upload link:
                    <div className="flex">
                      <div className="flex-1 mr-2">
                        <input
                          value={uploadURL}
                          readOnly
                          className="w-full p-1 border-2 outline-none border-green-600 focus:border-2 focus:border-gray-600 rounded-md focus:rounded-md"
                          ref={urlInput}
                        ></input>
                      </div>
                      <div className="w-1/4">
                        <button
                          onClick={copyURL}
                          className="border-2 w-full h-full border-green-600 rounded-md outline-none hover:bg-green-800 hover:text-white"
                        >
                          Copy
                        </button>
                      </div>
                    </div>
                    <div className="w-1/3 mx-auto my-3">
                      <button
                        className="border-2  w-full h-full border-green-600 rounded-md outline-none hover:bg-green-800 hover:text-white"
                        onClick={() => {
                          changeUploaded(false);
                          changeSubmitting(false);
                        }}
                      >
                        Upload More
                      </button>
                    </div>
                  </div>
                </div>
              </>
            )}
          </form>
        </div>
      </div>
    </Layout>
  );
}
